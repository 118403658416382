/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react';
import Select from 'react-select';
import { ButtonGroupComponent } from '../../components';

export default (props) => {
    const [selectedFoodCategories, setSelectedFoodCategories] = useState(null);
    const [selectedFoodOptions, setSelectedFoodOptions] = useState([]);
    const [cookTime, setCookTime] = useState('');
    const [servingSize, setServingSize] = useState('');
    const [selectedSystem, setSelectedSystem] = useState('imperial');

    const foodCategoryOptions = [
        { value: 'any', label: 'Any' },
        { value: 'asian', label: 'Asian' },
        { value: 'american', label: 'American' },
        { value: 'italian', label: 'Italian' },
        { value: 'mexican', label: 'Mexican' },
        { value: 'french', label: 'French' },
        { value: 'chinese', label: 'Chinese' },
        { value: 'japanese', label: 'Japanese' },
        { value: 'korean', label: 'Korean' },
        { value: 'indian', label: 'Indian' },
        { value: 'thai', label: 'Thai' },
        { value: 'vietnamese', label: 'Vietnamese' },
        { value: 'spanish', label: 'Spanish' },
        { value: 'greek', label: 'Greek' },
        { value: 'turkish', label: 'Turkish' },
        { value: 'lebanese', label: 'Lebanese' },
        { value: 'moroccan', label: 'Moroccan' },
        { value: 'ethiopian', label: 'Ethiopian' },
        { value: 'brazilian', label: 'Brazilian' },
        { value: 'argentinian', label: 'Argentinian' },
        { value: 'peruvian', label: 'Peruvian' },
        { value: 'caribbean', label: 'Caribbean' },
    ];

    const foodOptionOptions = [
        { value: 'vegan', label: 'Vegan' },
        { value: 'vegetarian', label: 'Vegetarian' },
        { value: 'gluten-free', label: 'Gluten Free' },
        { value: 'dairy-free', label: 'Dairy Free' },
        { value: 'low-carb', label: 'Low Carb' },
        { value: 'keto', label: 'Keto' },
        { value: 'paleo', label: 'Paleo' },
        { value: 'halal', label: 'Halal' },
        { value: 'kosher', label: 'Kosher' },
        { value: 'organic', label: 'Organic' },
        { value: 'low-sodium', label: 'Low Sodium' },
        { value: 'low-fat', label: 'Low Fat' },
        { value: 'sugar-free', label: 'Sugar Free' },
        { value: 'high-protein', label: 'High Protein' },
        { value: 'low-calorie', label: 'Low Calorie' },
        { value: 'raw', label: 'Raw' },
        { value: 'whole30', label: 'Whole30' },
        { value: 'nut-free', label: 'Nut Free' },
        { value: 'soy-free', label: 'Soy Free' },
        { value: 'shellfish-free', label: 'Shellfish Free' },
        { value: 'egg-free', label: 'Egg Free' },
    ];

    const handleFoodCategoryChange = (selectedOption) => {
        setSelectedFoodCategories(selectedOption);
    };

    const handleFoodOptionChange = (selectedOptions) => {
        setSelectedFoodOptions(selectedOptions);
    };

    const handleCookTimeChange = (e) => {
        const inputTime = parseInt(e.target.value);
        if (inputTime >= 1 && inputTime <= 360) {
            setCookTime(inputTime);
        }
    };

    const handleServingSizeChange = (e) => {
        const inputSize = parseInt(e.target.value);
        if (inputSize >= 1 && inputSize <= 24) {
            setServingSize(inputSize);
        }
    };

    const selectStyleStayOnTop = {
        control: (provided) => ({
            ...provided,
            'z-index': 9999,
        }),
        menu: (provided) => ({
            ...provided,
            'z-index': 9999,
        })
    }

    return (
        <div className="container desktop-container is-primary-white-bg" style={{ marginBottom: '10px' }}>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label className="label">Food Category</label>
                        <div className="control">
                            <Select
                                name="foodCategory"
                                value={selectedFoodCategories}
                                onChange={handleFoodCategoryChange}
                                options={foodCategoryOptions}
                                className="basic-single"
                                classNamePrefix="select"
                                styles={selectStyleStayOnTop}
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">Food Options</label>
                        <div className="control">
                            <Select
                                isMulti
                                name="foodOptions"
                                value={selectedFoodOptions}
                                onChange={handleFoodOptionChange}
                                options={foodOptionOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={selectStyleStayOnTop}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label className="label">Cook Time (Minutes)</label>
                        <div className="control">
                            <input
                                className="input"
                                type="number"
                                value={cookTime}
                                onChange={handleCookTimeChange}
                                min="1"
                                max="360"
                                placeholder="Enter cook time in minutes (optional)"
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">Serving Size</label>
                        <div className="control">
                            <input
                                className="input"
                                type="number"
                                value={servingSize}
                                onChange={handleServingSizeChange}
                                min="1"
                                max="24"
                                placeholder="Enter serving size (optional)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <label className="label">Preferred Units of Measurement</label>
                    <ButtonGroupComponent setSelectedSystem={setSelectedSystem} />
                </div>
            </div>
            <div className="field">
                <div className="control center-everything">
                    <button className="button is-primary is-rounded is-fullwidth desktop-button-container" onClick={() => {
                        const foodCategories = selectedFoodCategories != null ? [selectedFoodCategories.label] : [];
                        const foodOptions = selectedFoodOptions != null ? selectedFoodOptions.map(item => item.label) : [];
                        props.generateCustom({
                            foodCategories,
                            foodOptions,
                            cookTime,
                            servingSize,
                            selectedSystem
                        });
                    }}>
                        Generate Custom
                    </button>
                </div>
            </div>
        </div>
    );
};