import React from 'react'

export default () => (
	<footer className="footer is-primary-bg"
		style={{
			// color: "white",
			// alignContent: "center",
			// alignItems: "center",
			// alignSelf: "center",
			// background: "#292929",
			paddingBottom: '40px'
		}}
	>
		<div className="container">
			<div className="columns">

				{/* INFO COLUMN */}
				<div className="column has-text-centered">
					<div className="content">
						<h5 className="title is-white-txt is-5" style={{ marginBottom: '8px' }}>
							Info
						</h5>
						{/* <a className="is-white-txt" href="/privacy"> Privacy Policy </a> |
						<a className="is-white-txt" href="/terms"> Terms Of Service</a> */}
						<p className="is-white-txt"> Powered By <strong className="is-white-txt">dadchef.io</strong> <br />
							Copyright {new Date().getFullYear()} <br />
						</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
)