import axios from 'axios';
import AuthStorage from './AuthStorage';
const API_ENDPOINT = 'https://api.dadchef.io';

export default class {

    buildAuthHeader() {
        return {
            headers: {
                Authorization: AuthStorage.getAuthToken()
            }
        }
    }

    /**
     * @param {*} authComponent (TODO: we support email but phone number support in future)
    */
    async sendAccessCode(authComponent) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/sendAccessCode`, {
                email: authComponent
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) { }
        return false;
    }

    async verifyLoginAccessCode(email, accessCode) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/verifyLoginAccessCode`, {
                email,
                accessCode
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data;
        } catch (e) { }
        return false;
    }

    /**
     * If Token valid 200 response and we are logged in
    */
    async isAccessTokenValid() {
        try {
            let response = await axios.get(`${API_ENDPOINT}/isAccessTokenValid`, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    async uploadFile(fileName, fileData) {
        try {
            const getPresignedUrlResponse = await axios.get(
                `${API_ENDPOINT}/getPresignedUrl`, {
                ...(this.buildAuthHeader()),
                params: {
                    objectName: fileName,
                },
            });
            if (getPresignedUrlResponse.status !== 200) {
                return null;
            }
            const presignedUrl = getPresignedUrlResponse.data.url;

            // TODO: check if image needs to be compressed.
            const putObjectResponse = await axios.put(presignedUrl, {}, {
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            });
            if (putObjectResponse.status !== 200) {
                return null;
            }
            return putObjectResponse;
        } catch (e) {
            console.log("ERROR UPLOADING IMAGE");
            return null;
        }
    }
}