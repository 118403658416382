import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export default (props) => {
  const shareData = {
    title: 'DadChef Personal Food Chef Generated Recipe!',
    text: props.text,
    url: 'https://app.dadchef.io/',
  };

  const share = () => {
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Shared successfully.'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      toast.error("Sorry your browser doesn't support this.");
    }
  };

  return (
    <div className="share-button">
      <button className="button is-primary" onClick={share}>
        <span>Share</span>
        <span className="icon">
          <FontAwesomeIcon icon={faShareNodes} />
        </span>
      </button>
    </div>
  );
}