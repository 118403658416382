import AuthStorage from '../AuthStorage';

/**
 * @param {*} 
 * AUTH CONNECTION BUILDING COMPONENT
 */
export const generateConnectionUrl = async () => {
	const authToken = await AuthStorage.getAuthToken();
	const WEBSOCKET_URL_CONNECTION = `socket.dadchef.io/?Authorization=${authToken}`;
	return WEBSOCKET_URL_CONNECTION;
}

export const openStreamWithSessionId = async (callback, onerror, onopen, onclose, isSsl = true) => {
	const URL = await generateConnectionUrl();
	await openStream(URL, callback, onerror, onopen, onclose, isSsl);
}

/*** WEBSOCKET CONTROL ***/
let ws = null;
export const openStream = async (address, callback, onerror, onopen, onclose, isSsl = true) => {
	if (ws === null) {
		const socketType = isSsl ? 'wss' : 'ws';
		ws = new WebSocket(`${socketType}://${address}`);
	}
	ws.onerror = () => {
		console.log('WebSocket error');
		onerror();
	};
	ws.onopen = () => {
		console.log('WebSocket connection established');
		onopen();
	};
	ws.onmessage = (message) => callback(message);
	ws.onclose = () => {
		ws = null;
		console.log('WebSocket connection closed');
		onclose();
	};
}

export const closeConnection = () => {
	if (ws != null) {
		ws.close();
	}
}

export const sendMessageObject = async (messageObject = { sessionId: "100", type: "HISTORY" }) => {
	if (ws === null) {
		return;
	}
	ws.send(JSON.stringify(messageObject));
}

export const sendMessage = async (messageText, data = null, owner = AuthStorage.getUserEmail()) => {
	if (!messageText && !data) {
		return;
	}
	// parse stream message
	// message = JSON.parse(message);
	if (ws === null) {
		return;
	}

	let messageObject = {
		type: "OPENAI_REQUEST",
		owner: owner,
		text: messageText,
		data: data,
		sentAt: new Date().toISOString()
	}
	ws.send(JSON.stringify(messageObject));
}

// export const getSentStreams = () => {
// 	return currentStreams;
// }