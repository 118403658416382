import React, { useState, useEffect } from "react";
import { Button, Columns, Container, Form, Icon } from 'react-bulma-components';
import { toast } from 'react-toastify';
import { Loading, Modals } from "../components";
import { AppApi, AuthStorage } from '../services';
const appApi = new AppApi();

export default (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState();
    const [verifyCode, setVerifyCode] = useState();
    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            // TODO: remove this, its for testing
            const loggedInEmailBypass = AuthStorage.getUserEmail();
            if (loggedInEmailBypass === 'bypass') {
                setIsLoading(false);
                props.onLoggedIn(true);
            }

            const authToken = AuthStorage.getAuthToken();
            if (!!authToken && await appApi.isAccessTokenValid()) {
                // TODO: maybe move this?, refresh profile here
                // const userProfile = await appApi.getUserProfile();
                // await AuthStorage.setUserProfile(userProfile);
                setIsLoading(false);
                props.onLoggedIn(true);
            }
            setIsLoading(false);
        })();
    }, []);

    const onSendCodeButtonPress = async () => {
        setIsLoading(true);
        // TODO: remove testing code below
        if (email === 'bypass') {
            // TODO: remove
            await AuthStorage.setUserEmail('bypass');
            setIsLoading(false);
            props.onLoggedIn(true);
            return;
        }

        if (!email) {
            toast.error("Must provide valid email.");
            setIsLoading(false);
            return;
        }
        if (await appApi.sendAccessCode(email)) {
            // pop up modal
            setModalActive(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        // toast error
        toast.error('Failure, Try Again.');
    };

    const onVerifyCodeButtonPress = async () => {
        // TODO: either here or in the verify get token and set it
        const verifyResult = await appApi.verifyLoginAccessCode(email, verifyCode);
        if (verifyResult) {
            await AuthStorage.setAuthToken(verifyResult.authToken);
            await AuthStorage.setUserEmail(email);
            // pop up modal
            setModalActive(false);
            props.onLoggedIn(true);
            return;
        }
        // toast error
        toast.error('Failure To Authenticate.');
    };

    return (
        <div className="centered" style={{ padding: '20px' }}>
            <Container>
                <Columns className="is-centered">
                    {!!isLoading && <Loading visible={isLoading} />}
                    {!isLoading &&
                        <Columns.Column className="is-5-tablet is-4-desktop is-4-widescreen">
                            <div className="box has-text-centered is-quadary-bg">
                                <Form.Field>
                                    <Form.Label className="has-text-centered is-primary-txt" size={'large'}>Login</Form.Label>
                                    {/* <p className="has-text-centered is-italic">
                                        (App in beta. If you haven't received an invite. Access will not be granted.)
                                    </p> */}
                                    <Form.Control>
                                        <Form.Input
                                            placeholder="Email"
                                            name="name"
                                            value={email}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                        <Icon align="left">
                                            <span className="icon">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </Icon>
                                    </Form.Control>
                                </Form.Field>
                                <Button.Group>
                                    <Button
                                        fullwidth
                                        rounded
                                        color="primary"
                                        onClick={onSendCodeButtonPress}>Sign Up / Login</Button>
                                </Button.Group>
                            </div>
                        </Columns.Column>}
                </Columns>
                <Modals.VerifyModal
                    onClose={() => { setModalActive(false) }}
                    show={modalActive}
                    onInputChange={(event) => { setVerifyCode(event.target.value) }}
                    onVerifyClick={onVerifyCodeButtonPress} />
            </Container>
        </div >
    );
}