import React, { useState } from 'react';
import './index.css';

const ButtonGroupComponent = (props) => {
    const [selectedSystem, setSelectedSystem] = useState('imperial');

    const handleSelect = (system) => {
        if (props.setSelectedSystem) {
            props.setSelectedSystem(system);
        }
        setSelectedSystem(system);
    }

    return (
        <div className="buttons has-addons">
            <button className={`button ${selectedSystem === 'imperial' ? 'is-selected' : ''}`}
                onClick={() => handleSelect('imperial')}>
                Imperial
            </button>
            <button className={`button ${selectedSystem === 'metric' ? 'is-selected' : ''}`}
                onClick={() => handleSelect('metric')}>
                Metric
            </button>
        </div>
    )
}

export default ButtonGroupComponent;