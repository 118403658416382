import React, { useState, useEffect } from 'react';

export default ({ imageUrl1, imageUrl2 }) => {
    const [imageLoaded1, setImageLoaded1] = useState(false);
    const [imageLoaded2, setImageLoaded2] = useState(false);

    useEffect(() => {
        const image1 = new Image();
        image1.onload = () => {
            setImageLoaded1(true);
        };
        image1.src = imageUrl1;

        const image2 = new Image();
        image2.onload = () => {
            setImageLoaded2(true);
        };
        image2.src = imageUrl2;
    }, [imageUrl1, imageUrl2]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '512px', width: '100%' }}>
                <div style={{ flex: 1, margin: 'auto', marginRight: '16px' }}>
                    {!imageLoaded1 && <p>Loading image 1...</p>}
                    {imageLoaded1 && <img src={imageUrl1} alt="Loaded 1" width="256" height="256" />}
                </div>
                <div style={{ flex: 1, margin: 'auto', marginLeft: '16px' }}>
                    {!imageLoaded2 && <p>Loading image 2...</p>}
                    {imageLoaded2 && <img src={imageUrl2} alt="Loaded 2" width="256" height="256" />}
                </div>
            </div>
            <p style={{ textAlign: 'center', marginBottom: '16px', fontStyle: 'italic' }}>These images are just potentially what your food could look like</p>
        </div>
    );
}