/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Container, Heading, Button, Section } from "react-bulma-components";
import { toast } from "react-toastify";
import { Loading, ShareButton } from "../../components";
import { AuthStorage } from "../../services";
import * as websocketClient from '../../services/websocket/client';
import Recipe from "./Recipe/index";
import CustomForm from "./CustomForm";
import testData from './testData';

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRandomSelected, setIsRandomSelected] = useState(false);
    const [isCustomSelected, setIsCustomSelected] = useState(false);
    // websocket related state
    const [connected, setConnected] = useState(false);
    const [message, setMessage] = useState('');
    const [resultText, setResultText] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);

    const openWss = async () => {
        if (connected) {
            return;
        }

        setIsLoading(true);
        websocketClient.openStreamWithSessionId(handleResponse, () => {
            setConnected(false);
            setIsLoading(false);
        }, () => {
            setConnected(true);
            setIsLoading(false);
        }, () => {
            setConnected(false);
            setIsLoading(false);
        }, true);
    }

    const handleResponse = (response) => {
        console.log("RESPONSE::::", response)
        if (response === null || response === undefined || response.data === null
            || response.data === undefined) {
            return;
        }
        let data;
        try {
            data = JSON.parse(response.data);
            data = data.data;
        } catch (e) {
            return;
        }
        // if (data.length === 0) {
        //     return;
        // }
        try {
            // console.log("DATA:");
            // console.log(data)
            // console.log(data.chatResult)
            // @ts-ignore
            setResultText(data.chatResult);
            try {
                setImageUrls(data.imageResult.data.map(item => item.url));
            } catch (e) {
                console.log("image failure");
                setImageUrls([]);
            }
            setIsLoading(false);
        } catch (e) {
            console.log("ERROR WITH SET RESPONSE DATA:", e);
            // wait 20 seconds before actually closing this out
            // TODO: fix in the backend when its been 30 seconds and the message is still coming.
            setTimeout(() => {
                setIsLoading(false);
            }, 20000);
        }
    };

    const sendMessage = (sendMessage = message, data = null, owner = AuthStorage.getUserEmail()) => {
        websocketClient.sendMessage(sendMessage, data, owner);
        setMessage('');
    }

    useEffect(() => {
        openWss();
    }, [connected]);

    useEffect(() => {
        return () => {
            console.log('componentWillUnmount', 'close connection');
            websocketClient.closeConnection();
        };
    }, []);

    const onRandomRecipeClick = () => {
        if (!connected) {
            toast.warn("Connecting try momentarily.", {
                isLoading: false,
                autoClose: 1000
            });
            return;
        }
        setIsLoading(true);
        sendMessage("Generate");
        // @ts-ignore NOTE: testing
        // setResultText(testData.RANDOM_REC_2);
    }

    const onCustomRecipeClick = () => {
        if (!connected) {
            toast.warn("Connecting try momentarily.", {
                isLoading: false,
                autoClose: 1000
            });
            return;
        }
        setIsCustomSelected(!isCustomSelected);
    }

    const generateCustom = async (options) => {
        console.log(options)
        setIsLoading(true);
        sendMessage("Custom Generate", options);
        // @ts-ignore NOTE: testing
        // setResultText(testData.RANDOM_REC_2);
    }

    return (
        <Section style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <Container className="" style={{ marginBottom: '20px' }}>

                <div className="columns">
                    <div className="column is-one-third has-text-right"></div>
                    <div className="column is-one-third has-text-centered">
                        <Heading className="has-text-centered" weight="semibold" size={3}>Personal Food Chef</Heading>
                    </div>
                </div>
                <Heading className="has-text-centered" weight="semibold" size={4}>Recipe Generator</Heading>

                {/* START CONTENT */}
                <div className="columns">
                    <div className="column" />
                    <div className="column is-one-quarter">
                        <Button.Group className="is-justify-content-center">
                            <Button
                                className={`${isRandomSelected ? 'is-code-capture-selection' : ''}`}
                                inverted={isRandomSelected}
                                fullwidth
                                rounded
                                color="primary"
                                onClick={() => { onRandomRecipeClick() }}>Random</Button>
                        </Button.Group>
                    </div>
                    <div className="column is-one-quarter">
                        <Button.Group className="is-justify-content-center">
                            <Button
                                className={`${isCustomSelected ? 'is-code-capture-selection' : ''}`}
                                inverted={isCustomSelected}
                                fullwidth
                                rounded
                                color="primary"
                                onClick={() => { onCustomRecipeClick() }}>Custom</Button>
                        </Button.Group>
                    </div>
                    <div className="column" />
                </div>
                {!!isCustomSelected && <div className="manage-margins"><CustomForm generateCustom={generateCustom} /></div>}

                {!!isLoading && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {!!connected && <p style={{ fontSize: '16px' }}>Hang tight while we build your recipe</p>}
                        {!!connected && <p style={{ marginBottom: '8px', fontSize: '16px' }}>(est. 15-30 seconds)</p>}
                        <Loading visible={isLoading} />
                    </div>
                )}

                {!!resultText && <div>
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                                {imageUrls[0] != null && <Recipe.RecipeImageLoader imageUrl1={imageUrls[0]} imageUrl2={imageUrls[1]} />}
                                <Recipe.Recipe recipeText={resultText} />
                            </div>
                        </div>
                    </div>
                    <ShareButton text={resultText} />
                </div>}

            </Container>
        </Section>
    )
}