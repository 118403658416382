import React from 'react';
import './Recipe.css';

const formatRecipe = (recipeText) => {
    // Split the recipe text into an array of lines
    const lines = recipeText.trim().split('\n');

    // Find the index of the "Ingredients:" and "Instructions:" headers
    const ingredientHeaderIndex = lines.findIndex((line) =>
        line.toLowerCase().includes('ingredients:')
    );
    const instructionHeaderIndex = lines.findIndex((line) =>
        line.toLowerCase().includes('instructions:')
    );

    // Create arrays of the ingredients and instructions
    const ingredients = lines.slice(ingredientHeaderIndex + 1, instructionHeaderIndex);
    const instructions = lines.slice(instructionHeaderIndex + 1);

    // Extract the "enjoy" part from the last instruction
    const lastInstruction = instructions[instructions.length - 1];
    const enjoyIndex = lastInstruction.toLowerCase().indexOf('enjoy');
    const updatedLastInstruction = lastInstruction.slice(0, enjoyIndex).trim();
    const enjoyLine = lastInstruction.slice(enjoyIndex).trim();

    instructions[instructions.length - 1] = updatedLastInstruction;

    // Return the formatted JSX
    return (
        <div className="recipe-container">
            <h1>{lines[0]}</h1>
            <div className="ingredient-header">Ingredients:</div>
            <ul>
                {ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                ))}
            </ul>
            <div className="instruction-header">Instructions:</div>
            <ol>
                {instructions.map((instruction, index) => (
                    <li key={index}>{instruction}</li>
                ))}
            </ol>
            <div className="enjoy">{enjoyLine}</div>
        </div>
    );
};

export default ({ recipeText }) => {
    return formatRecipe(recipeText);
};