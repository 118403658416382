import React from "react"

export default (props) => {
    return (
        <div>
            <section className="hero is-primary-white-background is-small is-bold">
                <div className="hero-body" style={{ paddingTop: '0px' }}>
                    <div className="container">
                        <div className="has-text-centered">
                            {props.hasTitle && <><span className="title is-3">Food Chef Guidelines - Easy as 1, 2, 3!</span>
                                <div style={{ marginBottom: '10px' }} /></>}
                        </div>

                        <div className="content">
                        </div>

                        <h2 className="subtitle has-text-centered">Please <a className="is-primary-txt" href="https://dadchef.io/contact-us" target="_blank">contact</a> if you have questions or want to support.</h2>
                    </div>
                </div>
            </section>
            {props.hasDivider && <div class="is-divider is-primary-dark-bg" style={{ color: 'black', width: '100%', height: '2px' }} />}
        </div>
    );
}