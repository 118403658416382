export default class {
    static sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getBase64 = async (file) => {
        return new Promise((myResolve, myReject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                console.log("success");
                myResolve(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                myReject(error);
            };
        });
    }
}